import { Component, Input, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MailingService } from './mailing.service';

//declare const Quill: any;
//import { ImageResize } from 'quill-image-resize-module';



//Quill.register('modules/imageResize', ImageResize);

@Component({
  selector: 'app-mailing',
  templateUrl: './mailing.component.html',
  styleUrls: ['./mailing.component.scss']
})
export class MailingComponent implements AfterViewInit, OnInit {


  @Input() listaid: Array<number>;
  @Input() url: any;

  constructor(public mailService: MailingService, public dialog: MatDialog) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }

  abrir_mailing(): void {

    const dimensiones = {height: 720, width: 700};
    const dialogRef = this.dialog.open(DialogMailingComponent, {
      width: dimensiones.width + 'px',
      height: dimensiones.height + 'px',
      panelClass: 'myapp-no-padding-dialog',
      disableClose: false,
      data: { listaidmailing: this.listaid, title: 'Mailing', url: this.url, dimensiones: dimensiones }
    });
   // dialogRef.updatePosition({ top: event.clientY +'px', left: event.clientX + 'px' });
    dialogRef.afterClosed().subscribe(result => {
      if ( result && typeof result === 'object' && result.constructor === Object ) {
        //

      }
    });
  }

}


/* ----------------------------------------------------------------  */
/*                      DIALOGO mailing CLIENTE                      */
/* ----------------------------------------------------------------  */

@Component({
  selector: 'app-dialogo-mailing',
  templateUrl: './dialog-mailing.html',
  styleUrls: ['./mailing.component.scss']
})
export class DialogMailingComponent  implements OnInit {
  dimensiones = {height: 100, width: 100};
  title: string;
  datos: any;
  mailing: any;
  desdeemails:any;

  listamailing_by_admin : Array<any>;
  listafirmas : Array<any>;
  remitente_seleccionado: any;

  fileToUpload: any;
  constructor(
    public dialogRef: MatDialogRef<DialogMailingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public mailService: MailingService ) {
        this.dimensiones = data.dimensiones;
        this.dimensiones.height = data.dimensiones.height - 62;
        this.title = data.title;
        const list_ids = data.listaidmailing.join()
        this.mailing = {desde: 'info@kosmetikon.es', asunto: '', cuerpo: '', attach: false, listaidsmailing: list_ids };
        this.title = '';
        console.log(this.mailing);
     }

    ngOnInit() {
      console.log(this.mailing);
      this.desdeemails = '';
      this.listamailing_by_admin = [];
      this.listafirmas = [];
      this.remitente_seleccionado = { info_firma : ''}
      this.mailService.getAdminswithemails(this.data.url)
                      .subscribe( data => {
                          this.listamailing_by_admin = data;
                          console.log(this.listamailing_by_admin);

                      })




    }
/*
seleccionadmindesde(admins) {
  if ( admins.length > 0 ) {
    console.log(admins);
    this.mailing.desde = admins.map( item => {
                                  return item.email;
                                }).join();
    // cargar todos las firmas de todos los usuarios añadidos

    this.listafirmas = [];

    const idusuarios = admins.map( item => {
                          return item.idusuario;
                        }).join();
    this.mailService.getfirmas_usuarios_seleccionados(idusuarios, this.data.url)
                    .subscribe( data => {
                      console.log(data);
                      this.listafirmas = data;
                    })

  }

}
*/


addFirma(firma) {
  console.log('AÑADIR FIRMA');
  console.log(firma);
  console.log(this.mailing);
  this.mailing.cuerpo = this.mailing.cuerpo + firma.info_firma;
}



    handleFileInput(files: FileList) {
      this.fileToUpload = files.item(0);
      this.mailing.attach = true;
    //  this.mailing.attach = files.item(0);
    }


    adjuntar_fichero() {
      this.mailService.postFile(this.fileToUpload).subscribe(data => {
        // do something, if upload success
           console.log('EUREKA FUNCIONA');
        }, error => {
          console.log(error);
        });
    }

    enviar_email(): void {

      this.mailService.sendMailing( this.mailing, this.fileToUpload, this.data.url  )
                          .subscribe(data => {
                            this.dialogRef.close(data);
                          });
    }

  changePosition() {
    this.dialogRef.updatePosition({ top: '50px', left: '50px' });
  }

}


/*
 COMPONENTE : mailing

 <app-mailing [listaid]="elementsselected" [url]="{sendmail: 'contactos/sendmailing', admins: 'contactos/getadminswithemails', firmas_admins: 'contactos/getfirmas_admins' }" ></app-mailing>

  [listaid] : Array de ids de los usuarios seleccionados, servirá para buscar el email de cada uno de ellos en el servidor.



*/


