import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DlgAddImageComponent } from '../dlg-add-image/dlg-add-image.component';
import { MainusuariosupportService } from '../../mainusuariosupport.service';
import { DlgEditImageComponent } from '../dlg-edit-image/dlg-edit-image.component';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { DlgBigImageComponent } from '../dlg-big-image/dlg-big-image.component';
import { AppServicioService } from '../../../app.servicio.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-gestor-imagenes',
  templateUrl: './gestor-imagenes.component.html',
  styleUrls: ['./gestor-imagenes.component.scss']
})
export class GestorImagenesComponent implements OnInit {
  // Tabla
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  displayedColumns = ["imageUrl", "title/description", "actions"];

  images: any[] = [];
  public filteredImages: any[] = [];
  env = environment;

  showData: string = 'card'
  breakpoint: any;
  // basePath = 'https://supporttest.kosmetikon.io/';


  constructor(private dialog: MatDialog,
    private mainusuariosupportService: MainusuariosupportService,
    private AppServicioService: AppServicioService) 
  {
    this.cargarImagenes();
  }

  ngOnInit(): void {
    this.breakpoint = (window.innerWidth <= 1500) ? 4 : 5;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 1500) ? 4 : 5;
  }

  onAddImage() {
    const dialogRef = this.dialog.open(DlgAddImageComponent, {
      width: '400px',
      disableClose: true,
    })

    dialogRef.afterClosed().subscribe((resp: any) => {
      if (resp !== false) {
        this.cargarImagenes();
      }
    })
  }

  cargarImagenes() {
  /*
    * Cargamos los datos de las imagenes.
    * Indispensable arreglar la Url para visualizar la imágen.
    * Developer: ASA, 8/11/2023 version: 1.1.6
  */
    this.mainusuariosupportService.getImageList().subscribe((data: any[]) => {
      this.images = data;
      this.images.forEach((image) => {
        image.imageUrl = this.fixImageUrl(image.imageUrl);
      });
      this.filteredImages = this.images;
      this.dataSource.data = this.images;
      this.dataSource.sort = this.sort;
    });
  }

  fixImageUrl(url: string): string {
    if (!url.includes('images/')) {
      url = url.replace('images', 'images/');
    }
    return `${this.env.baseUrl}${url}`;
  }


  onEdit(imageToEdit: any) {
  /*
    * Editamos una imagen.
    * Developer: ASA, 8/11/2023 version: 1.1.6
  */
    const dialogRef = this.dialog.open(DlgEditImageComponent, {
      width: '500px',
      disableClose: true,
      data: imageToEdit,
    })

    dialogRef.afterClosed().subscribe((resp: any) => {

      if (resp !== false) {
        this.cargarImagenes();
      }
    })
  }


  onDelete(idImage: string) {
  /*
    * Eliminamos una imagen.
    * Developer: ASA, 8/11/2023 version: 1.1.6
  */
    this.mainusuariosupportService.deleteImage(idImage).subscribe({
      next: (resp: any) => {
        this.cargarImagenes();
      },
      error: (error: any) => {
        console.log(error)
      }
    })
  }


  onDownload(imageUrl: any, imageName:string) {
  /*
    * Enviamos la petición para poder descargar la imagen, enviamos la URL y el nombre de la imagen.
    * Developer: SAR, 8/11/2023 version: 1.1.6
  */

    this.mainusuariosupportService.downloadImage(imageUrl,imageName).subscribe({
      next: (resp: any) => {
        // saveAs(resp, imageUrl);
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  openBigImage(image: any) {
  /*
    * Al dar click, agranda la imagen.
    * Developer: ASA, 8/11/2023 version: 1.1.6
  */
    this.dialog.open(DlgBigImageComponent, {
      disableClose: false,
      data: image,
    })
  }

  copiarAlPortapapeles(imageUrl: string) {
    /*
      * Copia la URL, para pegarlo en otra página.
      * Developer: ASA, 8/11/2023 version: 1.1.6
    */
    this.AppServicioService.copiarAlPortapapeles(imageUrl);
  }

  changeShowData(element: string) {
    this.showData = element;
  }

  applyFilter(filtro: string) {
    /*
      * Diferencia entre vista de tarjetas o vista de tabla.
      * Recibe el parámetro filtro, que es loq ue se escribe en el Input.
      * En tarjeta filtra por titulo o descripción.
      * En tabla filtramos usando angular material.
      * Developer: SAR, 8/11/2023 version: 1.1.6
    */
    if (this.showData === 'card') {
      this.filteredImages = this.images.filter((item) =>
        item.description.toLowerCase().includes(filtro.toLowerCase()) ||
        item.title.toLowerCase().includes(filtro.toLowerCase())
      );
    }
    if (this.showData === 'table') {
      this.dataSource.filter = filtro;
    }
  }
}
