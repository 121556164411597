import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainusuariosupportComponent } from './mainusuariosupport/mainusuariosupport.component';
import { MainusuarioRoutingModule } from './mainusuariosupport-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MenuverticalawesomeModule } from 'src/app/comun/menuverticalawesome/menuverticalawesome.module';
//import { SupportModule } from '../multiusuario/support/support.module';
import { LearningModule } from '../multiusuario/learning/learning.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { MatDialogModule } from '@angular/material/dialog';
import { GestorImagenesModule } from './gestorimagenes/gestor-imagenes.module';

@NgModule({
  imports: [
    CommonModule, MainusuarioRoutingModule, ReactiveFormsModule, FormsModule,
    MatIconModule, MatButtonModule, MatCheckboxModule, MatDialogModule,
    MatSidenavModule, MenuverticalawesomeModule,MatTooltipModule, GestorImagenesModule
  ],
  declarations: [MainusuariosupportComponent, WhatsappComponent],
  exports: [ MainusuariosupportComponent ]
})
export class MainusuariosupportModule { }
