<div class="scroll-container">

    <div class="button">
        <button mat-raised-button extended class="boton_Añadir round-button" (click)="onAddImage()">
            <mat-icon>add_photo_alternate</mat-icon>
            Agregar Imagen
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menuSelectWayToView" style="text-align: right;">
            <mat-icon style="color: #A2BEED; ">more_vert</mat-icon>
        </button>
        <mat-menu #menuSelectWayToView="matMenu"> 
            <button mat-menu-item (click)="changeShowData('table')">
                <mat-icon matTooltip="Pasar a tabla"
                class="buttons_secondary" style="color: #A2BEED">table_rows</mat-icon>
                <span style="margin-left: 10px;">Pasar a tabla</span>
            </button>
            <button mat-menu-item (click)="changeShowData('card')">
                <mat-icon matTooltip="Pasar a tarjetas"
                class="buttons_secondary" style="color: #A2BEED">dashboard_customize</mat-icon>
                <span style="margin-left: 10px;">Pasar a tarjetas</span>
            </button>
        </mat-menu>
    </div>

    <div style="margin-top: 20px;">
        <app-filtros (outFilterField)="applyFilter($event)"></app-filtros>
    </div>

    <div *ngIf="showData==='card'" class="container_grid">
        <mat-grid-list [cols]="breakpoint" gutterSize="30px" rowHeight="350px" (window:resize)="onResize($event)">
            <mat-grid-tile *ngFor="let image of filteredImages">
                <mat-card class="card mat-elevation-z8">
                    <div class="title_container">
                        <!-- <div mat-card-avatar[style.background-image]="'url(' + image.imageUrl + ')'" class="example-header-image"></div> -->

                        <div class="card_title">
                            <h4 [matTooltip]="image.title">{{ image.title }}</h4>
                        </div>

                        <button mat-icon-button [matMenuTriggerFor]="menu" style="text-align: right; width: 20px;">
                            <mat-icon style="color: #A2BEED; ">more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button class="button-matMenu" mat-menu-item (click)="onEdit(image)">
                                <mat-icon class="buttons_secondary" style="color: #A2BEED">edit</mat-icon>
                                <span>Editar Imagen</span>
                            </button>
                            <button class="button-matMenu" mat-menu-item (click)="onDownload(image.imageUrl, image.fileNameNew)">
                                <mat-icon class="buttons_secondary" style="color: #A2BEED">download</mat-icon>
                                <span>Descargar Imagen</span>
                            </button>
                            <button class="button-matMenu" mat-menu-item (click)="onDelete(image.id)">
                                <mat-icon class="buttons_secondary" style="color: #A2BEED">delete</mat-icon>
                                <span>Eliminar Imagen</span>
                            </button>
                            <button class="button-matMenu" mat-menu-item (click)="copiarAlPortapapeles(image.imageUrl)">
                                <mat-icon class="buttons_secondary" style="color: #A2BEED">content_copy</mat-icon>
                                <span>Copiar al portapapeles</span>
                            </button>
                        </mat-menu>

                    </div>

                    <mat-card-content>
                        <div class="content_container">
                            <div class="image_container">
                                <img mat-card-image [src]="image.imageUrl" [alt]="image.title"
                                    (click)="openBigImage(image)" class="card_image"
                                    style="width: 100%; height: 150px; margin-top: 0 !important;">
                            </div>
                            <div class="description_container">
                                <p [matTooltip]="image.description" style="text-transform: capitalize;">{{
                                    image.description }}</p>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

    <div class="table-container" *ngIf="showData==='table'">
        <table mat-table [dataSource]="dataSource" matSort aria-label="Elements">
            <ng-container matColumnDef="imageUrl">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span class="table-header">{{'Imagen'}}</span>
                </th>
                <td mat-cell *matCellDef="let row">
                    <div class="img-contain">                    
                        <img class="img-table" (click)="openBigImage(row)" [src]="row.imageUrl">
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="title/description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span class="table-header">{{'Titulo/Descripción'}}</span>
                </th>
                <td mat-cell *matCellDef="let row">
                    <div class="div_titulo_descripcion">
                        <p>{{row.title}}</p>
                        <p>{{row.description }}</p>                 
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span>{{ 'Acciones' }}</span>
                </th>
                <td mat-cell *matCellDef="let row">
                    <div class="menu">
                        <button mat-icon-button [matMenuTriggerFor]="menu" style="text-align: right;">
                            <mat-icon style="color: #A2BEED; ">more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button class="button-matMenu" mat-menu-item (click)="onEdit(row)">
                                <mat-icon class="buttons_secondary" style="color: #A2BEED">edit</mat-icon>
                                <span>Editar Imagen</span>
                            </button>
                            <button class="button-matMenu" mat-menu-item (click)="onDownload(row.imageUrl, row.fileNameNew)">
                                <mat-icon class="buttons_secondary" style="color: #A2BEED">download</mat-icon>
                                <span>Descargar Imagen</span>
                            </button>
                            <button class="button-matMenu" mat-menu-item (click)="onDelete(row.id)">
                                <mat-icon class="buttons_secondary" style="color: #A2BEED">delete</mat-icon>
                                <span>Eliminar Imagen</span>
                            </button>
                            <button class="button-matMenu" mat-menu-item (click)="copiarAlPortapapeles(row.imageUrl)">
                                <mat-icon class="buttons_secondary" style="color: #A2BEED">content_copy</mat-icon>
                                <span>Copiar al portapapeles</span>
                            </button>
                        </mat-menu>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="50"  class="" [pageSizeOptions]="[25, 50, 100, 250]">
        </mat-paginator>
    </div>

</div>