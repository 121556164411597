import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as fileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class MainusuariosupportService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getallInterfaceLabels(idioma): Observable<any[]> {
    return this.http.post<any[]>(`${this.baseUrl}traducciones/getallLabelsbySubgrupo`, {idioma: idioma, modulo: 'SUPPORT', subgrupo: 'menu' });
  }

  newImage(data: any, file: File): Observable<any[]> { 
    const formData = new FormData();
    formData.append('files', file[0]);
    formData.append('id', data.id);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('fileNameNew', data.fileNameNew);
    // formData.append('fileNameOld', data.fileNameOld);

    return this.http.post<any[]>(`${this.baseUrl}chatsupport/newImage`, formData);
  }

  getImageList(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}chatsupport/getImageList`)
  }

  // updateImage(data: any, file: any): Observable<any[]>{
  
  //   const formData = new FormData();
  //   formData.append('files', file);
  //   formData.append('id', data.id);
  //   formData.append('title', data.title);
  //   formData.append('description', data.description);
  //   formData.append('fileNameNew', data.fileNameNew);
  //   formData.append('fileNameOld', data.fileNameOld);

  //   return this.http.patch<any[]>(`${this.baseUrl}chatsupport/updateImage`, formData)
  // }

  updateInfoImage(id:string, title: string, description: string): Observable<any[]>{
    return this.http.patch<any[]>(`${this.baseUrl}chatsupport/updateImage`, {id, title, description})
  }

  deleteImage(dataId: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}chatsupport/deleteImage`, { id: dataId }) 
  } 
  
  downloadImage(imageUrl: string, imageName: string): Observable<Blob> {
    return this.http.get(`${imageUrl}`, {responseType: 'blob'})
    .pipe(
      tap( content => {
        const blob = new Blob([content]);
        fileSaver.saveAs(blob, imageName)
      }),
    )
  }
  
//   downloadImage(image: any): Observable<any> {
//     const downloadUrl = `${this.baseUrl}chatsupport/download-image?image=${image}`;
//     return this.http.get(downloadUrl, { responseType: 'blob' });
//  }
}
