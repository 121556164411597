import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestorImagenesRoutingModule } from './gestor-imagenes-routing.module';

//* Modules

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//* Material Modules
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DlgAddImageComponent } from './dlg-add-image/dlg-add-image.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { GestorImagenesComponent } from './gestor-imagenes/gestor-imagenes.component';
import { DlgEditImageComponent } from './dlg-edit-image/dlg-edit-image.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DlgBigImageComponent } from './dlg-big-image/dlg-big-image.component';
import { ComunModule } from 'src/app/comun/comun.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
  declarations: [DlgAddImageComponent, GestorImagenesComponent, DlgEditImageComponent, DlgBigImageComponent],
  imports: [
    CommonModule, GestorImagenesRoutingModule, MatMenuModule,
    MatToolbarModule, MatCardModule, MatButtonModule, MatIconModule,
    MatDialogModule, MatFormFieldModule, MatInputModule, MatTooltipModule,
    FormsModule, ReactiveFormsModule, MatGridListModule, NgxDropzoneModule,
    ComunModule, MatTableModule, MatPaginatorModule, MatDividerModule
  ]
})
export class GestorImagenesModule { }
