import { Component, OnInit } from '@angular/core';
import { CurrentuserService } from '../../comun/currentuser.service';
import { Router } from '@angular/router';
import {trigger, transition, style, animate, state} from '@angular/animations';
import { MainusuariosupportService } from '../mainusuariosupport.service';
import { MatDialog } from '@angular/material/dialog';
import { WhatsappComponent } from '../whatsapp/whatsapp.component';

@Component({
  selector: 'app-mainusuario',
  templateUrl: './mainusuariosupport.component.html',
  styleUrls: ['./mainusuariosupport.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ]),
  ]
})
export class MainusuariosupportComponent implements OnInit {


  enlacesmenu: Array<any>;
  colormenu: any;
  font: any;
  showmenu: boolean;
  staterotate: string;
  claseTema:string;
  isAdmin: boolean;
  userInfo: string;

  constructor(private router: Router, public dialog: MatDialog, public currentuserservice: CurrentuserService, public mainusuariosupportService: MainusuariosupportService) {
    this.enlacesmenu = [
                          [{name: 'soporte', link: '/main/support_kosmetikon', icon: 'fa-bug', id: 2, submenu: []},
                          {name: 'learning', link: '/main/learning_kosmetikon', icon: 'fa-info', id: 3, submenu: []},
                          {name: 'empresas', link: '/main/clientes', icon: 'fa-industry', id: 4, submenu: []},
                          {name: 'administracion', link: '/main/administracion', icon: 'fa-key', id: 5, submenu: []},
                          {name: 'imagenes', link: '/main/gestor_imagenes', icon: 'fa-image', id: 6, submenu: []},
                  //        {name: 'contratos', link: '/main/contratos', icon: 'warning', id: 4, submenu: []}
                          ],
                          [{name: 'SALIR', link: '/', icon: 'fa-power-off', id: 12, submenu: []}]
                      ];




    this.isAdmin = this.currentuserservice.isAdmin();
    this.userInfo = currentuserservice.getfullnamelong() + '\n' + currentuserservice.get_myempresa() + '\n' + (this.isAdmin ? 'ADMINISTRADOR' : '');

    this.colormenu =  { itemseleccionado: {color: 'white', background: '#448AFF'},
                        itementrar: {color: '#3F51B5', background: '#F5F5F5'},
                        itemsalir: {color: '#3F51B5', background: 'white'},
                      };
    this.font = {'font-family': 'Arial, "Helvetica Neue", Helvetica, "sans-serif" ', 'font-size': '12px'};
    this.showmenu = false;

    this.claseTema = 'kosThemeDark';
  }


  ngOnInit() {
    const idioma = this.currentuserservice.getInterfaceLanguage();
    this.mainusuariosupportService.getallInterfaceLabels(idioma).subscribe( data => {
        if(this.isAdmin == true){
          this.enlacesmenu = [
            [{name: data.filter( item => item.codigo_texto === 'soporte').map(value => value.texto).join(), link: '/main/support_kosmetikon', icon: 'fa-bug', id: 2, submenu: []},
            {name: data.filter( item => item.codigo_texto === 'ayuda').map(value => value.texto).join(), link: '/main/learning_kosmetikon', icon: 'fa-info', id: 3, submenu: []},

            {name: data.filter( item => item.codigo_texto === 'empresas').map(value => value.texto).join(), link: '/main/clientes', icon: 'fa-industry', id: 4, submenu: []},
            {name: data.filter( item => item.codigo_texto === 'administracion').map(value => value.texto).join(), link: '/main/administracion', icon: 'fa-key', id: 5, submenu: []},
            {name: data.filter( item => item.codigo_texto === 'imagenes').map(value => value.texto).join(), link: '/main/gestor_imagenes', icon: 'fa-image', id: 6, submenu: []},
            // {name: data.filter( item => item.codigo_texto === 'ayuda').map(value => value.texto).join(), link: '/main/contratos', icon: '', id: 4, submenu: []}

            ],
            [{name: data.filter( item => item.codigo_texto === 'salir').map(value => value.texto).join(), link: '/', icon: 'fa-power-off', id: 12, submenu: []}]
          ];
        }else{
          this.enlacesmenu = [
            [{name: data.filter( item => item.codigo_texto === 'soporte').map(value => value.texto).join(), link: '/main/support_kosmetikon', icon: 'fa-bug', id: 2, submenu: []},
            {name: data.filter( item => item.codigo_texto === 'ayuda').map(value => value.texto).join(), link: '/main/learning_kosmetikon', icon: 'fa-info', id: 3, submenu: []},

            // {name: data.filter( item => item.codigo_texto === 'ayuda').map(value => value.texto).join(), link: '/main/contratos', icon: '', id: 4, submenu: []}

            ],
            [{name: data.filter( item => item.codigo_texto === 'salir').map(value => value.texto).join(), link: '/', icon: 'fa-power-off', id: 12, submenu: []}]
          ];
        }
                                      
                                    });

 }


  // openDialog(): void {
  // const dialogRef = this.dialog.open(WhatsappComponent, {
  //   height: '500px',
  //   width: '340px',
  //   data: {
  //     imagePath: '/assets/icons/whatsapp_kos3.png'
  //   }
  // });
  // }

  onModulo_seleccionado(event) {
  //  console.log($event);
  }


  onLogout() {
    this.router.navigate(['/']);
  }
}
