<!-- <h1 mat-dialog-title>Editar Imagen</h1> -->

<div class="header">
    <div class="title">
        <h3>{{'Editar Imagen'}}</h3>
    </div>
    <button class="close-button" (click)="onClose()" style="color: white;">X</button>
</div>

<div *ngIf="!newImage" class="image_preview_div">
    <img [src]="previewImage" class="image_preview">
</div>
<br>

<form [formGroup]="form" (ngSubmit)="editImage()">

    <mat-dialog-content>
        <!-- <ngx-dropzone (change)="onSelect($event)" *ngIf="showDropZone">
            <ngx-dropzone-label>Seleccione la imagen ...</ngx-dropzone-label>
            <ngx-dropzone-image-preview  ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
        </ngx-dropzone> -->

        <mat-form-field class="example-full-width">
            <mat-label>Título de la imagen</mat-label>
            <input matInput formControlName="title" placeholder="Título" require>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="description" placeholder="Descripción de la imagen" require></textarea>
        </mat-form-field>

    </mat-dialog-content>
    <mat-error *ngIf="form.invalid" class="error">{{'** Todos los campos son requeridos **'}}</mat-error>
    <mat-dialog-actions>
        <button type="button" mat-stroked-button (click)="cancelButton()" style="color: #5582BD;">Cancelar</button>
        <button type="submit" mat-raised-button style="background-color: #5582BD; color: white;"
            cdkFocusInitial>Editar</button>
    </mat-dialog-actions>

</form>