<!--
<div class="menuvertical">

    <div class="logo">
            <img src="assets/logo.jpg" style="width:40px;border-radius:10%">
    </div>
    <div *ngFor="let menu of menulista; index as i"   [ngStyle]="cambiarColorItemMenu(i)" (mouseenter) ="itementra = i"  (mouseleave) ="itementra = -1" (click)="seleccionarItem( menu, i )" style="padding:10px 10px;border:1px solid #F5F5F5;cursor:pointer;">
        <div style="color:inherit;background-color:inherit;cursor:pointer" [ngStyle]="font" >
            <div>{{ menu.name }}</div>
            <ng-container  *ngIf="menu.submenu.length > 0">
                <div *ngFor="let sm of menu.submenu" style="min-height:28px;margin-bottom:2px;display:flex;flex-direction:column;justify-content:center;align-items:flex-start;padding-left:10px;">
                    <div style="background-color:yellow;">{{sm.name }}</div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

-->
<div class="menuvertical">
    <div class="logo">
            <img src="assets/logo.jpg" style="width:40px;border-radius:10%">
        
    </div>
    <div *ngFor="let group of menulista" style="margin-bottom:20px;">
        <div *ngFor="let menu of group; index as i"   [ngStyle]="cambiarColorItemMenu(menu.id)" (mouseenter) ="itementra = menu.id"  (mouseleave) ="itementra = -1" (click)="seleccionarItem( menu, i )" style="padding:6px 6px;border:1px solid #F5F5F5;cursor:pointer;">
            <div style="color:inherit;background-color:inherit;cursor:pointer;display:flex;flex-direction: row;align-items: center;" [ngStyle]="font" >
                <div style="width:26px;color:#3D5AFE;">
                    <!--
                    <mat-icon svgIcon="{{ menu.icon }}" class="icondefault"  style="width:14px !important;"  ></mat-icon> 
                    -->
                    <ng-container *ngIf="menu.icon || menu.icon !== '' ">
                        
                    <i class="fa"  [ngClass]="menu.icon"  aria-hidden="true"></i>
                    </ng-container>
                </div>

                <div style="flex:1;">{{ menu.name }}</div>
                <div style="width:30px;color:#1E88E5;display:flex;flex-direction:row;justify-content:flex-end;">
                    <i class="fa fa-angle-right" aria-hidden="true"  [ngStyle]="cambiarColorItemMenu(menu.id)"  ></i>
                </div>

                <ng-container  *ngIf="menu.submenu.length > 0">
                    <div *ngFor="let sm of menu.submenu" style="min-height:26px;margin-bottom:2px;display:flex;flex-direction:column;justify-content:center;align-items:flex-start;padding-left:10px;">
                        
                        <div style="background-color:yellow;">{{sm.name }}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

