import { Component, OnInit, Input, Output, EventEmitter, OnChanges, } from '@angular/core';
import { Router } from '@angular/router';
// Array<Object> = [{name:'texto1', link:'enlace1'}, {name:'texto2', link:'enlace2'}, {name:'texto3', link:'enlace3'}]
@Component({
  selector: 'app-menuverticalawesome',
  templateUrl: './menuverticalawesome.component.html',
  styleUrls: ['./menuverticalawesome.component.scss']
})
export class MenuverticalawesomeComponent implements OnInit, OnChanges {
  @Input() menulista: Array <any>;
  @Input() indiceitemseleccionado: number;
  @Input() menutipo: string;
  @Input() menucabecera: string;
  @Input() colorsitems: any;
  @Input() font: any;
  @Output() moduloseleccionado = new EventEmitter();

  itementra: number;
  itemseleccionado: number;

  vector: Array<any>;

  constructor(private router: Router) {
    this.menutipo = 'simple';
    this.menucabecera = 'Esta es la cabecera';
    this.itementra = -1;
    this.itemseleccionado = -1;
  }

  ngOnInit() {
    console.log(this.indiceitemseleccionado);
    console.log('==== MENU LISTA =====')
    console.log(this.menulista);
    const itemseleccionado = this.menulista[this.indiceitemseleccionado];
    this.itemseleccionado = this.indiceitemseleccionado;
    this.moduloseleccionado.emit({modulo: itemseleccionado.name});
  }

  ngOnChanges() {
    // console.log('==== MENU LISTA =====')
    this.menulista = this.menulista.map(menuItem => {
      return menuItem.map(subMenuItem => {
        if (subMenuItem.name === '') {
          return { ...subMenuItem, name: 'GESTOR DE IMAGENES' };
        }
        return subMenuItem;
      });
    });
    console.log(this.menulista);
  }

  seleccionarItem (item, index) {
    console.log('ITEM DE MENU');
    console.log(item);
    this.itemseleccionado = item.id;
    this.router.navigate([item.link]);
    this.moduloseleccionado.emit({modulo: item.name});
  }

  cambiarColorItemMenu(index) {
    let colors = {};

    if ( index === this.itementra) {
      // cuando entra
       if ( this.itementra === this.itemseleccionado) {
         // esta seleccionado
        colors = {'background-color': this.colorsitems.itemseleccionado.background, 'color': this.colorsitems.itemseleccionado.color};
       } else {
         // no esta seleccionado
         colors = {'background-color': this.colorsitems.itementrar.background, 'color': this.colorsitems.itementrar.color};
        }
    } else {
      // cuando sale
      if ( index === this.itemseleccionado ) {
        // esta seleccionado
        colors = {'background-color': this.colorsitems.itemseleccionado.background, 'color': this.colorsitems.itemseleccionado.color};
      } else {
        // no esta seleccionado
        colors = {'background-color': this.colorsitems.itemsalir.background, 'color': this.colorsitems.itemsalir.color};
      }
    }
    return colors;
  }


}

