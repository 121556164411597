<div class="contenedor-login">
    <div class="contenedor-login-titulo">
        <div><img [src]="'assets/logo.svg'" style="height:70px;"></div>
    </div>
    <div class="tp-wrapper">
        <form [formGroup]="myForm" >
            <div class="tp-box"  [@flipState]="flip" >
                <div class="tp-box__side tp-box__front" >
                        <div style="display:flex;flex-direction:column;justify-content: flex-start;align-items: center;margin-top:25px;">
                            <div style="display:flex;flex-direction:row;align-items:center;height:40px;color:black;font-size:small;">
                                <mat-icon svgIcon="user" class="icondefault" style="margin-right:10px;width:16px !important" ></mat-icon>

                                <mat-form-field [floatLabel]="'never'">
                                <input matInput  formControlName="name"  name="name"  style="font-size:11px;" >
                                </mat-form-field>
                            </div>

                            <div style="display:flex;flex-direction:row;align-items:center;height:40px;color:black;font-size:small;">
                                <mat-icon svgIcon="password" class="icondefault" style="margin-right:10px;width:16px !important" ></mat-icon>

                                <mat-form-field [floatLabel]="'never'">
                                    <input type="password" matInput  formControlName="pass" name="pass"  (keyup)="$event.keyCode == 13 ? loguearse() : null"  >
                                </mat-form-field>
                            </div>
                            <div style="display:flex;flex-direction:column;align-items: center;height:40px;margin-top:10px;">
                                <button mat-raised-button style="color:#1976d2" class="small" (click)="loguearse()"> {{ labels.aceptar }} </button>
                                <span (click)="toggleFlip()" style="cursor:pointer;font-size:10px;margin-top:20px;color:grey;"><u> {{ labels.questionpasswordremember }} </u></span>
                            </div>

                        </div>
                </div>
                <div class="tp-box__side tp-box__back" >
                        <div style="display:flex;flex-direction:column;justify-content: center;align-items: center;padding-top:60px;">
                            <div style="color:#42a5f5;">{{ labels.enteryouremail }}</div>
                            <div  style="display:flex;flex-direction:row;align-items:center;">
                                <mat-form-field [floatLabel]="'never'" style="margin-right:10px;">
                                        <input matInput formControlName="pass" name="pass" (keyup)="$event.keyCode == 13 ? loguearse() : null" >
                                </mat-form-field>
                                <button mat-raised-button color="primary" class="small"  (click)="toggleFlip()">{{ labels.send }}</button>
                            </div>
                        </div>
                </div>
            </div>
        </form>

    </div>
</div>
<div class="cartel" [@shakeMe]="activaranimacion"  >Bienvenid@ a KOSMETIKON !<br> Welcome to KOSMETIKON !</div>
<div class="lado-izquierdo" > {{ env.version }} - {{ env.releaseDate }}</div>

<div class="lado-derecho">KOSMETIKON <sup>
  <mat-icon svgIcon="copyright" class="icondefault" style="width:10px;"></mat-icon>
</sup>
</div>
