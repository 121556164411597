import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainusuariosupportComponent } from './mainusuariosupport/mainusuariosupport.component';


const mainusuarioRoutes: Routes = [

    { path: 'main', component: MainusuariosupportComponent,
            children: [
              { path: '', redirectTo: 'support_kosmetikon', pathMatch: 'full' },
              { path: 'support_kosmetikon', loadChildren: () => import('./../multiusuario/support/support.module').then(m => m.SupportModule) },
              { path: 'learning_kosmetikon', loadChildren: () => import('./../multiusuario/learning/learning.module').then(m => m.LearningModule) },
              { path: 'contratos', loadChildren: () => import('./../multiusuario/validacioncontratos/validacioncontratos.module').then(m => m.ValidacioncontratosModule) },
              { path: 'administracion', loadChildren: () => import('./../administracion/administracion.module').then(m => m.AdministracionModule) },
              { path: 'clientes', loadChildren: () => import('./clientes/clientes.module').then(m => m.ClientesModule) },
              { path: 'contactos', loadChildren: () => import('./contactos/contactos.module').then(m => m.ContactosModule) },
              { path: 'gestor_imagenes', loadChildren: () => import('./gestorimagenes/gestor-imagenes.module').then(m => m.GestorImagenesModule) },

            ]
    }
  ];

@NgModule({
    imports: [RouterModule.forChild(mainusuarioRoutes)],
    exports: [RouterModule]
})

export class MainusuarioRoutingModule { }
