import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainusuariosupportService } from '../../mainusuariosupport.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dlg-edit-image',
  templateUrl: './dlg-edit-image.component.html',
  styleUrls: ['./dlg-edit-image.component.scss']
})
export class DlgEditImageComponent {

  //* Variables
  form: FormGroup;
  file!: File;
  url!: any;
  previewImage = this.data.imageUrl;
  showDropZone = false;
  canClose:boolean = false;

  newImage: boolean = false;

  constructor(private fb: FormBuilder,
    private mainusuariosupportService: MainusuariosupportService,
    private dialogRef: MatDialogRef<DlgEditImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.onInitForm(data);
  }

  onInitForm(data){
  /*
    * Inicializamos el formulario
    * Developer: SAR, 8/11/2023, version: 1.1.6
  */
    this.form = this.fb.group({
      image: [null],
      title: [data.title, [Validators.required]],
      description: [data.description, [Validators.required]]
    })
  }

  editImage() {
  /*
    * Se ejecuta al dar click en guardar.
    * Abstraemos el titulo y la descripción del formulario.
    * Remplazamos la comilla simple, por la doble, ya que genera problemas en las DB.
    * Hacemos la petición para actualización de la información.
    * No podemos editar la imagen.
    * Developer: SAR, 8/11/2023, version: 1.1.6
  */
    let {title, description} = this.form.value;

    title = title.replaceAll("'", "''");
    description = description.replaceAll("'", "''");

    this.mainusuariosupportService.updateInfoImage(this.data.id,title, description).subscribe((data: any) => {
      this.dialogRef.close({title, description});
    })

  };

  cancelButton() {
  /*
    * Cerramo el dialogo.
    * Developer: SAR, 8/11/2023, version: 1.1.6
  */
    this.dialogRef.close(false)
  }

  onClose(): void {
  /*
    * Cerramos el dialogo.
    * Developer: SAR, 8/11/2023, version: 1.1.6
  */
    const mensaje='se cerro sin hacer nada';
    this.canClose = true;

    if (this.canClose) {
      this.dialogRef.close( mensaje );
    }
  }

}

