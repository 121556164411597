<div >
  <div *ngIf="modoicono" class="contenedor-clip">
      <label for="avatar"><i class="fa fa-paperclip fa-lg" style="color:inherit;" ></i></label>
      <input type="file" id="avatar" (change)="onFileChange($event)" #fileInput style="display: none;">
  </div>
  <div *ngIf="! modoicono" >
      <label for="avatar">{{texto}}</label>
      <input type="file" id="avatar" (change)="onFileChange($event)" #fileInput style="display: none;">
  </div>
</div>
