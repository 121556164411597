import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

export const shakeMe = trigger('shakeMe', [
  transition('* => in', animate('1000ms cubic-bezier(.36,.07,.19,.97)', keyframes([
                                                            style({transform: 'rotate(-2deg)', offset: 0.2}),
                                                            style({transform: 'rotate(2deg)', offset: 0.3}),

                                                            style({transform: 'rotate(-2deg)', offset: 0.4}),
                                                            style({transform: 'rotate(2deg)', offset: 0.5}),

                                                            style({transform: 'rotate(-2deg)', offset: 0.6}),
                                                            style({transform: 'rotate(2deg)', offset: 0.7}),

                                                            style({transform: 'rotate(-2deg)', offset: 0.8}),                                                          
                                                            style({transform: 'rotate(2deg)', offset: 0.9})                                                          
                                                          ]  ) ) ),
]);