import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})
export class FiltrosComponent implements OnInit {


  @Input() literales: any;
  @Output() outFilterField = new EventEmitter<string>();

  filterField = new FormControl('')

  ngOnInit(): void {
    this.filterField.valueChanges.pipe(
      debounceTime(500)
    ).subscribe( data => {
      this.outFilterField.emit(data.toLocaleLowerCase());
    })
  }

}
