import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginService } from './login.service';
import { CurrentuserService } from '../comun/currentuser.service';
import { SupportloginComponent } from './supportlogin/supportlogin.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule, MatButtonModule,
    MatCardModule, MatFormFieldModule, MatInputModule,
    FormsModule, ReactiveFormsModule
  ],
  declarations: [
    SupportloginComponent
  ],
  exports: [
    SupportloginComponent
  ],
  providers: [LoginService, CurrentuserService ]

})
export class LoginModule { }
