import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class MailingService {

  baseUrl = environment.baseUrl;

  constructor( private http: HttpClient ) { }


  getMails( listuserids: any) {
    return this.http.post<any>(`${this.baseUrl}mailing/sendMailing`, { listid: listuserids });
  }


  getAdminswithemails (url) {
    return this.http.get<any>(`${this.baseUrl}${ url.admins }`);
  }


  getfirmas_usuarios_seleccionados (idusuarios, url) {
    return this.http.post<Array<any>>(`${this.baseUrl}${ url.firmas_admins }`, { idusuarios: idusuarios});
  }



  sendMailing( mailing: any, file, url) {
          const formData: FormData = new FormData();
          if ( mailing.attach ) {
            formData.append('file', file, file.name);

          } else {
            formData.append('file', null, null);

          }
          formData.append('mailing', JSON.stringify(mailing));
          const headers = new HttpHeaders();
          headers.append('Content-Type', 'multipart/form-data');
          return this.http.post<any>(`${this.baseUrl}${url.sendmail}`, formData, { headers: headers });

  }

/*
  postFile( file: any ) {
    return this.http.post<any>(`http://localhost:3000/mailing/sendFile`, formData, options )
  }

*/
  postFile(fileToUpload: File): Observable<boolean> {
    const url = 'http://localhost:3000/mailing/sendFile';
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post<any>(url, formData, { headers: headers });
   //   .catch((e) => this.handleError(e));
}


}

/*

export class FileUpload {

  @ViewChild('selectedFile') selectedFileEl;

  uploadFile() {
  let params = new HttpParams();

  let formData = new FormData();
  formData.append('upload', this.selectedFileEl.nativeElement.files[0])

  const options = {
      headers: new HttpHeaders().set('Authorization', this.loopBackAuth.accessTokenId ),
      params: params,
      reportProgress: true,
      withCredentials: true,
  }

  this.http.post('http://localhost:3000/api/FileUploads/fileupload', formData, options)
  .subscribe(
      data => {
          console.log("Subscribe data", data);
      },
      (err: HttpErrorResponse) => {
          console.log(err.message, JSON.parse(err.error).error.message);
      }
  )
  */

  /*

  */


  /*
    import { Injectable } from '@angular/core';
    import {HttpClient, HttpParams, HttpRequest, HttpEvent} from '@angular/common/http';
    import {Observable} from "rxjs";

    @Injectable()
    export class UploadService {

      constructor(private http: HttpClient) { }

      // file from event.target.files[0]
      uploadFile(url: string, file: File): Observable<HttpEvent<any>> {

        let formData = new FormData();
        formData.append('upload', file);

        let params = new HttpParams();

        const options = {
          params: params,
          reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.http.request(req);
      }
    }

  */

/*
  Define simple input tag of type file. Add an function to (change)-event for handling choosing files.

<div class="form-group">
    <label for="file">Choose File</label>
    <input type="file"
           id="file"
           (change)="handleFileInput($event.target.files)">
</div>
Step 2: Upload Handling in TypeScript (file-upload.component.ts)

Define an default variable for selected file.

fileToUpload: File = null;
Create function which you use in (change)-event of your file input tag:

handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
}
If you will to handle multifile selection, than you can iterate through this files array.

Now create file upload function by calling you file-upload.service:

uploadFileToActivity() {
    this.fileUploadService.postFile(this.fileToUpload).subscribe(data => {
      // do something, if upload success
      }, error => {
        console.log(error);
      });
  }
Step 3: File-Upload Service (file-upload.service.ts)

By uploading a file via POST-method you should use FormData, because so you can add file to http request.

postFile(fileToUpload: File): Observable<boolean> {
    const endpoint = 'your-destination-url';
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.httpClient
      .post(endpoint, formData, { headers: yourHeadersConfig })
      .map(() => { return true; })
      .catch((e) => this.handleError(e));
}
So this is very simple working example, which I use every day in my work.

*/
