import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { MainusuariosupportModule } from './mainsupport/mainusuariosupport.module';
import { LoginModule } from './login/login.module';
import { PagenofoundComponent } from './pagenofound/pagenofound.component';

@NgModule({
  declarations: [
    AppComponent,
    PagenofoundComponent,

  ],
  imports: [
    MatIconModule, AppRoutingModule, MatSnackBarModule,
    HttpClientModule,
    MainusuariosupportModule, LoginModule,
    BrowserAnimationsModule,
  ],
  providers: [MatIconRegistry, {provide: MAT_DATE_LOCALE, useValue: 'es-ES'} ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(public matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');


    this.matIconRegistry.addSvgIcon('menu', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu.svg'));
    this.matIconRegistry.addSvgIcon('more', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/more.svg'));
    this.matIconRegistry.addSvgIcon('power', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/power.svg'));
    this.matIconRegistry.addSvgIcon('user', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/profile.svg'));
    this.matIconRegistry.addSvgIcon('password', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/lock.svg'));

    this.matIconRegistry.addSvgIcon('add', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/add.svg'));
    this.matIconRegistry.addSvgIcon('delete', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/delete.svg'));
    this.matIconRegistry.addSvgIcon('edit', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/edit.svg'));

    this.matIconRegistry.addSvgIcon('comment', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/comment.svg'));

    this.matIconRegistry.addSvgIcon('heart', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/heart.svg'));
    this.matIconRegistry.addSvgIcon('heart-selected', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/heart-selected.svg'));

    this.matIconRegistry.addSvgIcon('hourglass', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/hourglass.svg'));
    this.matIconRegistry.addSvgIcon('hourglass-start', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/hourglass-start.svg'));
    this.matIconRegistry.addSvgIcon('hourglass-half', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/hourglass-half.svg'));
    this.matIconRegistry.addSvgIcon('card', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/big-id-card.svg'));
    this.matIconRegistry.addSvgIcon('check', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/tick.svg'));

    this.matIconRegistry.addSvgIcon('attachment', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/attachment.svg'));

    this.matIconRegistry.addSvgIcon('warning', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/warning.svg'));
    this.matIconRegistry.addSvgIcon('pdf', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/pdf.svg'));
    this.matIconRegistry.addSvgIcon('video-player', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/video-player.svg'));
    this.matIconRegistry.addSvgIcon('sendchat', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/send.svg'));
    this.matIconRegistry.addSvgIcon('copyright', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/copyright.svg'));

    this.matIconRegistry.addSvgIcon('star', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/star.svg'));
    this.matIconRegistry.addSvgIcon('star-selected', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/star-selected.svg'));

    this.matIconRegistry.addSvgIcon('filter', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/filter-outline.svg'));
    this.matIconRegistry.addSvgIcon('filter-remove', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/filter-remove-outline.svg'));
  }

 }
