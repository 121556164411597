import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
import { MainusuariosupportService } from '../../mainusuariosupport.service';

@Component({
  selector: 'app-dlg-add-image',
  templateUrl: './dlg-add-image.component.html',
  styleUrls: ['./dlg-add-image.component.scss']
})
export class DlgAddImageComponent {

  //* Variables
  form: FormGroup;
  file!: File;
  url!: any;
  validation: boolean = false;
  canClose:boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DlgAddImageComponent>,
    private mainusuariosupportService: MainusuariosupportService
  ){ 
    this.form = this.fb.group({
      image: [null, [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]]
    })
  }

  addImage() {
    /*
      * Verifica si todos los campos están completados.
      * Obtenemos la información del formulario.
      * Generamos la inforamción de la imagen.
      * hacemos la petición apra generar la imagen.
    */
    if (this.form.invalid) {
      this.validation = true;
      return;
    }
    
    this.validation = false;
    const { title, description, image } = this.form.value;
    const dataResult = this.generateImageData({ title, description, image });
  
    this.mainusuariosupportService.newImage(dataResult, image).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  generateImageData({ title, description, image }) {
    /*
      * Generamos un Id aleatorio.
      * generamos la dat de la imagen.
    */
    const idGenerate = uuidv4();
    return {
      id: idGenerate,
      title: title.toUpperCase(),
      description: description,
      fileNameNew: `${idGenerate.slice(0, 13)}_${title}_${image[0].name}`,
      fileNameOld: null 
    };
  }
  
  onSelect(event: any) {
    /*
      * añadimos al campo image la inforamción de la imagen subida.
      * Guardaremos solo una imagen.
    */
    this.form.patchValue({image: event.target.files});
    this.file = event.target.files[0];
  }

  cancelButton() {
  /*
    * Cerramos el dialogo.
    * Developer: SAR, 8/11/2023, version: 1.1.6
  */
    this.dialogRef.close(false)
  }

  onClose(): void {
  /*
    * Cerramos el dialogo.
    * Developer: SAR, 8/11/2023, version: 1.1.6
  */
    const mensaje='se cerro sin hacer nada';
    this.canClose = true;

    if (this.canClose) {
      this.dialogRef.close( mensaje );
    }
  }

}
