
<div class="header">
    <div class="title">
        <h3>{{'Añadir Imagen'}}</h3>
    </div>
    <button class="close-button" (click)="onClose()" style="color: white;">X</button>
</div>


<form [formGroup]="form" (ngSubmit)="addImage()" >

    <mat-dialog-content class="container_inputs">

        <div class="center">
            <button (click)="fileInput.click()" mat-stroked-button 
            color="primary" class="loadFiles-button" type="button">
                Cargar Archivos
            </button>
            <input hidden #fileInput type="file" (change)="onSelect($event)">
        </div>
        
        <div *ngIf="file" class="div_imagenes">
            <p>{{file.name}}</p>
        </div>

        <mat-form-field class="cajatexto" style="width:100%;">
            <mat-label>Título de la imagen</mat-label>
            <input type="text" matInput formControlName="title">
        </mat-form-field>

        <mat-form-field class="cajatexto" style="width:100%;">
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="description" style="height: 50px;"></textarea>
        </mat-form-field>

    </mat-dialog-content>

    <div class="display_center">
        <mat-error *ngIf="validation" class="error">{{'Todos los campos son requeridos'}}</mat-error>
    </div>

    <mat-dialog-actions>
        <button type="button" mat-stroked-button (click)="cancelButton()" style="color: #5582BD;" >Cancelar</button>
        <button type="submit" mat-raised-button style="background-color: #5582BD; color: white;" cdkFocusInitial 
        >Agregar</button>
    </mat-dialog-actions>

</form>