import { Component, OnInit, Input } from '@angular/core';
import * as ExcelJs from 'exceljs/dist/exceljs.min.js';
//import { saveAs } from 'file-saver/FileSaver';
//import { saveAs } from 'file-saver';

@Component({
  selector: 'app-listaexcel',
  templateUrl: './listaexcel.component.html',
  styleUrls: ['./listaexcel.component.scss']
})
export class ListaexcelComponent implements OnInit {
  @Input() lista: any;
  @Input() cabecera: any;
  
  constructor() { }

  ngOnInit() {
    console.log(this.lista);

  }

  generarexcel() {
      var workbook = new ExcelJs.Workbook();
      var ws = workbook.addWorksheet('My Hoja');
      
      var row = ws.getRow(5);
      
      console.log('GENERAR EXCEL DENTRO');
      console.log(this.cabecera);

      if ( this.cabecera.length >0 ) {
        for ( let item of this.cabecera) {
            if ( item.merge ) {
              ws.mergeCells(item.cell);
            }
            ws.getCell(item.cell).alignment = item.alignment;
            ws.getCell(item.cell).border = item.border;            
            ws.getCell(item.cell).font = item.font;
            ws.getCell(item.cell).value = item.value;
        }
      }

      if ( this.lista.campos.length > 0 && this.lista.vector.length > 0 ) {
          //existen datos para imprimir la tabla.
          let i = this.lista.filainicio;
          let row = ws.getRow(i);
          let j = 1;
          // escribiendo las cabeceras de tabla de datos
          for ( let item of this.lista.campos) {
              row.getCell(j).value = item.titulo;
              row.getCell(j).style.font = { name: 'Arial', family: 4, size: 12, underline: 'double', bold: true };
              j+=1;
          }
        
          // escribiendo las cuerpo de tabla de datos
          for ( let item of this.lista.vector) {
             i+=1;
             row = ws.getRow(i);
             j = 1;
            for ( let cabecera of this.lista.campos) {
              row.getCell(j).value = item[cabecera.field];
              row.getCell(j).style.font = { name: 'Arial', family: 4, size: 12, underline: 'none', bold: false };
              j+=1;
            }
        }


      }
/*
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], {
            type: "application/octet-stream"
        }), `file.xlsx`);
      });  
*/  
  }

}


/*
lista : array de la lista a imprimir
cabecera: objeto con textos que hay que colocar en la cabecera
          Ejemplo:
          cabecera = [
                      { cell:'A1', value:'titulo 1', merge:false, alignment:{ vertical: 'middle', horizontal: 'center' }, 
                        border:{ top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}},
                        font: { name: 'Arial Black', color: { argb: 'FF00FF00' }, family: 2, size: 14, italic: true} 
                      }, 
                      { cell:'B1', value:'titulo 2', merge:false, alignment: { vertical: 'top', horizontal: 'left' }, 
                        border:{ top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}} 
                        font: { name: 'Arial Black', color: { argb: 'FF00FF00' }, family: 2, size: 14, italic: true} 
                      }, 
                      ...,
                      { cell:'C1:C10', value:'titulo 2', merge:true, alignament: { vertical: 'bottom', horizontal: 'right' }, 
                        border:{ top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}} 
                        font: { name: 'Arial Black', color: { argb: 'FF00FF00' }, family: 2, size: 14, italic: true} 
                      }
                     ];

lista : { filainicio: numero, vector:[], campos:[]}

  filainicio: fila donde empezara a escribir los titulos de los campos y a partir de la cual escribirá el array o tabla de datos
       Ejemplo 
           filainicio : 8 
  vector: será un array con n filas y m campos
        Ejemplo:
        vector = [{field_1:'texto1', field_2:'texto2'...field_n:'texto n'},
                  {field_1:'texto1', field_2:'texto2'...field_n:'texto n'}, 
                  ..., 
                  {field_1:'texto1', field_2:'texto2'...field_n:'texto n'} ]

  campos: será la lista de campos que se iran añadiendo dentro de la hoja excel.
        Ejemplo
        campos = [ {field:'nombre_campo',titulo:'nombre campo'}, 
                   {field:'nombre_campo2',titulo:'nombre campo2'}, 
                   {field:'nombre_campo3',titulo:'nombre campo3'}
                   ..., 
                   {field:'nombre_campo n',titulo:'nombre campo n'}]

*/