import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule} from '@angular/material/snack-bar';

import { SnackbarComponent } from './snackbar.component';
import { MatCardModule } from '@angular/material/card';



@NgModule({
  declarations: [SnackbarComponent],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatCardModule
  ],
  exports: [ SnackbarComponent ]
})
export class SnackbarModule { }