import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class AppServicioService {
  baseUrl = environment.baseUrl;

  idioma = new BehaviorSubject<string>("es");
  idioma$ = this.idioma.asObservable();

  listaLiterales = new BehaviorSubject<any>({});
  listaLiterales$ = this.listaLiterales.asObservable();

  appTheme = new BehaviorSubject<string>("kosThemeDay");
  appTheme$ = this.appTheme.asObservable();

  tiempoInactividad = new BehaviorSubject<number>(60);
  tiempoInactividad$ = this.tiempoInactividad.asObservable();

  prefijoOrdenEnvasado = new BehaviorSubject<string>("");
  prefijoOrdenEnvasado$ = this.prefijoOrdenEnvasado.asObservable();

  prefijoOrdenFabricacion = new BehaviorSubject<string>("");
  prefijoOrdenFabricacion$ = this.prefijoOrdenFabricacion.asObservable();


  constructor(private http: HttpClient,
              private _snackBar: MatSnackBar) {
    /*
    -------Abu revisar este, hay que quitar estos lebals*/
    this.getAllLiterales(this.idioma.getValue());
    console.log('getting sisten idioma ');
    console.log(this.idioma.getValue());
    
    this.getLoadAppSettings();
    
  }

  getAllLiterales(idioma) {
    this.idioma.next(idioma);
    
    this.http.post<any>(`${this.baseUrl}traducciones/getallLabel`, {idioma: idioma})
               .subscribe( data => {
                this.listaLiterales.next(data);
              }); 
  }
  


  //----------------------------------------------------------------------//
  getGUIDuuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  //----------------------------------------------------------------------//
  getLoadAppSettings() {
    this.http
      .get<any>(`${this.baseUrl}traducciones/getLoadAppSettings`)
      .subscribe((data) => {
        this.appTheme.next(data[0].FASAPPARENCE);
        this.tiempoInactividad.next(data[0].FASINACTIVIDAD * 60);
        this.prefijoOrdenFabricacion.next(data[0].FASPREFORDENFAB);
        this.prefijoOrdenEnvasado.next(data[0].FASPREFORDENENV);
      });
  }

  //----------------------------------------------------------------------//
  getAppTheme() {
    return this.appTheme$;
  }

  //----------------------------------------------------------------------//
  setAppTheme(tema) {
    this.appTheme.next(tema);
  }

  //----------------------------------------------------------------------//
  getTiempoInactividad(): Observable<number> {
    return this.tiempoInactividad$;
  }

  //----------------------------------------------------------------------//
  setIdiomaLiterales() {}

  //----------------------------------------------------------------------//
  getIdioma() {
    return this.idioma$;
  }

  //----------------------------------------------------------------------//
  getLiterales() {
    return this.listaLiterales$;
  }

  //----------------------------------------------------------------------//
  translateMatPaginator(paginator: MatPaginator) {
    const listaliterales = this.listaLiterales.getValue();
    paginator._intl.firstPageLabel = listaliterales.fabkosfirstpage;
    paginator._intl.itemsPerPageLabel = listaliterales.fabkositemsperpage;
    paginator._intl.lastPageLabel = listaliterales.fabkoslastpage;
    paginator._intl.nextPageLabel = listaliterales.fabkosnextpage;
    paginator._intl.previousPageLabel = listaliterales.fabkospreviouspage;
    paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${listaliterales.fabkospdfof} ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} ${
        listaliterales.fabkospdfof
      } ${length}`;
    };
  }
  //----------------------------------------------------------------------//

  copiarAlPortapapeles(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.left = '0';
    textArea.style.top = '0';
    textArea.style.opacity = '0';
    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      const message = 'Dirección de enlace copiada al portapapeles'
      this._snackBar.open(message, '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });

    } catch (err) {
      this._snackBar.open(`No se pudo copiar el texto`, '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    }

    document.body.removeChild(textArea);
  }
}
