<div>
    <i class="fas fa-info-circle fa-2x" style="color: #5d8ac3; margin-right: 5px"></i> {{ textoMain }}
</div>

<mat-card *ngFor="let name of lista; let idx = index" class="card">
    <u>{{name.nombre_empresa}} ({{name.tipolicencia}}): </u>
    <br/>
    <label style="font-size: 11px;">
        {{text1[idx]}} 
    </label>
</mat-card>