import { Component, OnInit } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
  selector: 'app-listapdf',
  templateUrl: './listapdf.component.html',
  styleUrls: ['./listapdf.component.scss']
})
export class ListapdfComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  generarlistapdf () {
    


    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
        arialnarrow: {
                normal: 'arialnarrow.ttf',
                bold: 'arialnarrowbold.ttf',
                italics: 'arialnarrowitalic.ttf',
                bolditalics: 'arialnarrowbolditalic.ttf'
        }
    };
    var dd = this.cargar_datos();
    pdfMake.createPdf(dd).download();
  }

  cargar_datos () {
      return {
                pageSize : 'A4',
                pageOrientation : 'portrait',
                pageMargins : [ 40, 45, 40, 45 ],
                header : function(currentPage, pageCount) {
                      var result = {};
                      if ( currentPage == 1 ) { result = {}; }
                      else {
                        result = {
                                        margin : [ 40, 35, 40,0],
                                        fontSize : 8,
                                        columns : [
                                                {
                                                    text : 'Report Nº  ' + 'datos',
                                                    alignment : 'left',
                                                    bold : true
                                                },
                                                {
                                                    text : 'Page ' + currentPage.toString() + ' / ' + pageCount,
                                                    alignment : 'right',
                                                    bold : true
                                                } ]
                                    };
                      }
                    return result;
                },
                footer : function(currentPage, pageCount) { 
                    var result = {};
                      result = 
                                {
                                    margin : [20,0,20,5],
                                    columns : [
                                            {
                                            style: 'tableHeader',
                                            table: {
                                                widths: ['*'],
                                                body: [
                                                    [ {text:'www.iecespagne.com', fontSize:8, bold:'true', color:'green', alignment:'center' }],
                                                    [ {text:'Passeig Sant Joan, 76 - 08009 BARCELONA - SPAIN / Tel. + (34) 932 469 218 - Fax +(34) 932 444 966 / info@iecespagne.com', fontSize:8, color:'green',alignment:'center' }],
                                                ]
                                            },
                                            layout:'noBorders'
                                            
                                            } ]
                                };
                    return result;                    

                },
                defaultStyle: {
                    font: 'arialnarrow'
                  },                
                content: [{text:'Esta es una prova'}]
            }
  }

}
