import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../login.service';
import { CurrentuserService } from 'src/app/comun/currentuser.service';
import { shakeMe } from 'src/app/comun/animaciones/shake';
import { flipState } from 'src/app/comun/animaciones/flipstate';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-supportlogin',
  templateUrl: './supportlogin.component.html',
  styleUrls: ['./supportlogin.component.scss'],
  animations: [ flipState, shakeMe ]
})
export class SupportloginComponent implements OnInit  {
 activaranimacion: string;
 //name: string;
 //pass: string;
 logueado: boolean;
 flip: string = 'inactive';
 labels: any;
 env = environment;
 //pagelogin: string;

 public myForm: FormGroup = this.fb.group({
  name: '',
  pass: ''
 })

  constructor(private loginService: LoginService,
              private fb: FormBuilder,
              private router: Router, private rutaactiva: ActivatedRoute,
              private currentuserservice: CurrentuserService) {

    this.logueado = false;
    this.activaranimacion = 'out';
    this.labels = {};

  }

  ngOnInit() {
    //  this.rutaactiva.data.subscribe( data => {  this.pagelogin = data.usertype; })
    this.rutaactiva.data.subscribe(params=>{
          if ( params ) {
                switch ( params.idioma ) {
                  case 'es':
                            this.labels.aceptar = 'Aceptar';
                            this.labels.questionpasswordremember = '¿ Has olvidado tu contraseña ?';
                            this.labels.enteryouremail = 'Introduzca su email';
                            this.labels.send = 'Enviar';
                            break;
                  case 'en':
                            this.labels.aceptar = 'OK';
                            this.labels.questionpasswordremember = '¿ Have you forgotten your password ?';
                            this.labels.enteryouremail = 'Enter your email';
                            this.labels.send = 'Send';
                            break;          
                  default:
                            this.labels.aceptar = 'Aceptar';
                            this.labels.questionpasswordremember = '¿ Has olvidado tu contraseña ?';
                            this.labels.enteryouremail = 'Introduzca su email';
                            this.labels.send = 'Enviar';
                }
          } else {

          }

    });
    this.activaranimacion = 'in';
  }



  loguearse () {
    const name = this.myForm.value.name;
    const pass = this.myForm.value.pass;
    //name, pass

    console.log('intto de loguearse')
    this.loginService.loguear({email: name, password: pass, pagelogin: 'support' }, 'login/loginsupport')
                      .subscribe( resp => {
                        console.log('despues de loguearse')
                            if ( resp.logueado ) {
                              console.log(resp);
                              this.currentuserservice.user = resp.data;
                              this.currentuserservice.set_portal_login('support');
                              
                              this.router.navigate(['/main']);
                            } else {
                              this.router.navigate(['/']);
                            }
                      });
  }


  toggleFlip() {
    this.flip = (this.flip == 'inactive') ? 'active' : 'inactive';
  }


}
