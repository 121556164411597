import { Injectable } from '@angular/core';

@Injectable()
export class CurrentuserService {
  user: any;
  portallogin: string;
  constructor() { }

  getuser() {
    return this.user;
  }

  isAdmin(): boolean{
    if (this.user.usertype == '2') {
      return true
    } else {
      return false
    }
  }
  getmail () {
    return this.user.email;
  }
  getuserrol () {
    return this.user.idRol;
  }

  getusertype() {
    return this.user.usertype;
  }

  getiduser() {
    return this.user.iduser;
  }

 getid_miempresa() {
    return this.user.idempresa;
 }

  get_myempresa() {
    return this.user.nombre_empresa;
  }

 getfullnameshort() {
  return this.user.name + ' ' + this.user.surname.charAt(0);
 }

 getfullnamelong() {
  return this.user.name + ' ' + this.user.surname;
 }
 getphoto() {
   return this.user.photo;
 }

 getInterfaceLanguage() {
  return this.user.idioma_interface;
 }

 getComunicationLanguage() {
  return this.user.idioma_comunicacion;
 }

 //analizar su influencia de gecodigoservicio reemplazar por get_lista_servicios y luego eliminar getcodigoservicio
 /*
 getcodigoservicio() {
   return this.user.codigoservicio;
 }
*/

 get_portal_login() {
  return this.portallogin;
 }

 set_portal_login(portallogin) {
  return this.portallogin = portallogin;
 }

 get_lista_servicios() {
  return this.user.servicios;
  // servicios = [{idservicio: 1 , tipo_servicio:'SOPORTE'}, {idservicio: 15 , tipo_servicio:'xxx'}]
}

get_lista_servicios_by_tipo(tipo_servicio) {
  let respuesta = [];
  if (this.user.servicios.length > 0 ) {
    respuesta = this.user.servicios.filter( item => item.tipo_servicio === tipo_servicio );
  }
  return respuesta;
}


 getexisteservicio(codservicio) {
   return this.user.services.indexOf(codservicio) > -1;
 }

 get_consentimiento_datos() {
   return this.user.consentimiento_datos;
 }

 set_consentimiento_datos(con) {
   this.user.consentimiento_datos = con;
 }

}
