import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class LoginService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private rutaactiva: ActivatedRoute) { }


  loguear(data, url): Observable<any>  {
    console.log('this.baseUrl');
    console.log(this.baseUrl);
    console.log(url);
    console.log(`${this.baseUrl}${url}`);
    console.log(this.http.post(`${this.baseUrl}${url}`, { data: data }));
    
    
    return  this.http.post(`${this.baseUrl}${url}`, { data: data });
  }


}

