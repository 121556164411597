<div class="cabecera">

    <div class="container-left">
        <div style="width:95px;">
            <button (click)="drawer.toggle()" mat-icon-button color="primary">
                    <mat-icon svgIcon="menu" class="icondefault" [@rotatedState]="staterotate" style="color:white;width:18px !important;" ></mat-icon>
            </button>
            <button (click)="onLogout()" mat-icon-button color="primary">
                        <mat-icon svgIcon="power" class="icondefault"  [@rotatedState]="staterotate" style="color:white;width:18px !important;" ></mat-icon>
            </button>
        </div>
            <button  mat-icon-button color="primary" class="whatsapp_button">
                <a href="https://api.whatsapp.com/message/QZAMLLP5KSSUP1" target="_blank">
                    <img src="https://cdn.icon-icons.com/icons2/373/PNG/256/Whatsapp_37229.png" alt="WhatsApp Icon" style="width:30px !important;" >
                </a>
                <!-- <mat-icon svgIcon="power" class="icondefault" [@rotatedState]="staterotate" style="color:white;width:18px !important;" ></mat-icon> -->
            </button>
    </div>
                
 
    
    <div>
        <div *ngIf="isAdmin" matTooltip="Administrador" class="is-admin"><span>Administrador</span></div>
    </div>
    <div class="usuario">

        <div class="fila is-admin">
            <div>{{ currentuserservice.getfullnamelong() }}</div>
            <div style="font-size:10px;">{{ currentuserservice.get_myempresa() }}</div>
        </div>
        <div style="margin:10px 10px 5px 10px;display:flex;flex-direction:row;justify-content: center;align-items: center;" matTooltip="{{userInfo}}" matTooltipClass="mattooltip-multiline">
            <img [src]="currentuserservice.getphoto()" style="height:40px;border-radius:50%">
        </div>

    </div>
</div>

<mat-drawer-container class="sidenav-container" autosize >
    <mat-drawer #drawer class="sidenav-header" [mode]="'over'" position="start" [opened]="showmenu">

        <app-menuverticalawesome [menulista]="enlacesmenu" [indiceitemseleccionado]="0" [menucabecera]="'Menu Principal'" [colorsitems]="colormenu" [font]="font" (moduloseleccionado)="onModulo_seleccionado($event)"></app-menuverticalawesome>

    </mat-drawer>
    <div class="sidenav-content">
        <router-outlet></router-outlet>
    </div>
</mat-drawer-container>