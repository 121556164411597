import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule} from '@angular/material/select';
import { MatMenuModule} from '@angular/material/menu';


import { MailingComponent, DialogMailingComponent } from './mailing/mailing.component';
import { MailingService } from './mailing/mailing.service';
import { UploadfileComponent } from './uploadfile/uploadfile.component';

import { ListapdfComponent } from './listapdf/listapdf.component';
import { ListaexcelComponent } from './listaexcel/listaexcel.component';
import { FiltrosComponent } from './filtros/filtros.component';
import { SnackbarModule } from './snackbar/snackbar.module';
import { MatFormFieldModule } from '@angular/material/form-field';
@NgModule({
  imports: [
    CommonModule, FormsModule, MatIconModule,
    MatButtonModule, MatDialogModule,
    MatInputModule, MatProgressBarModule, MatSelectModule, MatMenuModule, SnackbarModule,
    MatFormFieldModule, ReactiveFormsModule
  ],
  declarations: [
  MailingComponent, DialogMailingComponent, UploadfileComponent,
  ListapdfComponent, ListaexcelComponent, FiltrosComponent

  ],
  entryComponents: [
    DialogMailingComponent
  ],
  exports: [
    MailingComponent, DialogMailingComponent, UploadfileComponent, FiltrosComponent
  ],
  providers: [MailingService ]

})
export class ComunModule { }
