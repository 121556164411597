//*   snack bar personalizado tipo componentes */
import { Component, Inject, OnInit } from "@angular/core";

import {
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";
import * as moment from 'moment';

@Component({
  selector: "app-snackbar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.scss"],
})
export class SnackbarComponent {
  textoMain: string = "";
  lista: Array<any> = [];
  actual:any;
  text1: Array<any> = [];
  //Le llegan los datos de la data de main.component.ts y los asigna a variables
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.textoMain = data.textoMain;
    this.lista = data?.lista ?? [];
    let i = 0;
    if(this.lista.length){
      for(let item of this.lista){
        this.text1[i] = item.diasparaCaducar <= 0 ? 
                                        this.text1[i] = 'Caducó: ' + this.fechaFormateada(item.fechafin) + ' (hace ' + Math.abs(item.diasparaCaducar) + ' días)' : 
                                        this.text1[i] = 'Caducará: ' + this.fechaFormateada(item.fechafin) + ' (faltan ' + item.diasparaCaducar + ' días)';
        i += 1;
      }
    }
  }

  fechaFormateada(data){
    return moment(data).format("DD/MM/YYYY");
  }
}