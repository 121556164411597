import { NgModule } from '@angular/core';
import { GestorImagenesComponent } from './gestor-imagenes/gestor-imagenes.component';
import { RouterModule, Routes } from '@angular/router';

const supportRoutes: Routes = [
    { path: '',  component: GestorImagenesComponent }
  ];
  

  @NgModule({
    imports: [RouterModule.forChild(supportRoutes) ],
    exports: [RouterModule]
  
  })
  export class GestorImagenesRoutingModule {}