import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuverticalawesomeComponent } from './menuvertical/menuverticalawesome.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  imports: [
    CommonModule, MatIconModule
  ],
  declarations: [MenuverticalawesomeComponent],
  exports: [MenuverticalawesomeComponent]

})
export class MenuverticalawesomeModule { }
