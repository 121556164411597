<div   class="dialogo-titulo" > <i class="fa fa-envelope-o" aria-hidden="mailing" style="color:white"></i> &nbsp; {{title}} </div>
<mat-dialog-content [ngStyle]="{ height: dimensiones.height + 'px', width: dimensiones.width - 30 + 'px'}">
    <div style="padding:0px 0px 5px 5px;display:flex; flex-direction: column; align-items: flex-start; justify-content: flex-start; overflow:hidden;"  [ngStyle]="{ height: dimensiones.height - 10 + 'px', width: dimensiones.width - 20 + 'px'}" >
        <section style="height:10px;width:100%;">
                <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        </section>
        <div style="min-height:130px !important;padding:6px 0px 5px 5px;width:100%;display:flex;flex-direction: column;justify-content: flex-start;align-items: stretch;" >
            <button class="small" mat-raised-button color="primary"  (click)="enviar_email()" style="margin-bottom:10px;height:30px;">
                <i class="fa fa-paper-plane-o" aria-hidden="adjuntar fichero" ></i>  &nbsp; Enviar
            </button>
            <div style="display:flex;flex-direction: row;justify-content: flex-start;align-items:center;">
                    <mat-form-field style="font-size:small;width:100%;">
                            <mat-select [(ngModel)]="remitente_seleccionado" name="remitente_seleccionado"  placeholder="Desde" >
                                <mat-option *ngFor="let admin of listamailing_by_admin" [value]="admin">
                                    <span style="font-size:11px;min-width:120px;">{{admin.email_asociado}}</span>
                                    <span style="color:grey;margin-left:10px;font-size:11px;min-width:120px"> ( {{admin.fullname }} )</span>
                                    <span style="font-size:11px;min-width:120px;">: {{admin.nombre_firma}}</span>

                                </mat-option>
                            </mat-select>
                    </mat-form-field>
                    <!--
                    <mat-menu #appMenu>
                            <ng-container *ngFor="let firma of listafirmas">
                                    <button   (click)="addFirma(firma)" mat-menu-item  class="small">
                                        {{ firma.nombre_firma }} ( {{ firma.nombre }}  {{ firma.apellido }} )
                                    </button>
                            </ng-container>
                        </mat-menu>

                        <button [matMenuTriggerFor]="appMenu" mat-button class="small">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                            Firmas
                        </button>
                    -->
            </div>
            <mat-form-field style="flex:1;height:40px !important;max-height:40px !important;font-size:small;width:100%;" >
                <input matInput  [(ngModel)]="mailing.asunto" placeholder="Asunto">
            </mat-form-field>
        </div>

        <div style="padding-left:5px;">
          <!--
                <quill-editor [style]="{height:'200px', 'background-color':'#FAFAFA'}"  [(ngModel)]="mailing.cuerpo"   ></quill-editor>
          -->
      </div>
      <div style="display:flex; flex-direction:row;align-items: center; justify-content: flex-start; align-self: flext-start;height:30px;">

          <div style="padding-top:10px;font-size:9px;display:flex;flex-direction: row; justify-content: flex-start; align-items: center;">
                    <div class="form-group">
                        <label for="file" style="padding:6px;border:1px solid #EEEEEE;box-shadow: 2px 2px #E0E0E0;cursor:pointer;">
                            <i class="fa fa-paperclip fa-lg" style="color:inherit;" ></i>
                            Adjuntar Fichero
                        </label>
                        <input type="file" id="file" (change)="handleFileInput($event.target.files)"  #fileInput style="display: none;" >
                    </div>
                    <div style="padding-left:15px;"> {{ fileToUpload ? fileToUpload.name : '' }} </div>
          </div>
      </div>
      <div style="width:100%;margin-top:10px;background-color:#FAFAFA;flex:1;display:flex;flex-direction:row;justify-content: stretch; align-items: stretch;overflow:auto;">

         <div [innerHTML]="remitente_seleccionado.info_firma"></div>

         <!--
         <mat-form-field [style.fontSize]="10">
                <mat-label>Firma</mat-label>
                <textarea matInput  [(ngModel)]="remitente_seleccionado.info_firma" name="info_firma"
                          cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="2"
                          cdkAutosizeMaxRows="5" [innerHTML]="remitente_seleccionado.info_firma" ></textarea>
              </mat-form-field>
             -->
      </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="display:flex; flex-direction:row;padding:0px;justify-content:flex-end;height:34px;border-radius:2px;">
    <div class="dialogo-inferior dialogo-contenido-centrar">
        <button class="small" mat-raised-button  mat-dialog-close >Cerrar</button>
    </div>
</mat-dialog-actions>

