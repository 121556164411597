import { Component, OnInit, Input, Output, EventEmitter, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-uploadfile',
  templateUrl: './uploadfile.component.html',
  styleUrls: ['./uploadfile.component.scss']
})
export class UploadfileComponent implements OnInit {
  @Input() modoicono: boolean;
  @Input() texto: string;
  @Input() defaultfolder: string;
  @Input() data: any;
  @Input() fileurl: string; // example:  http://localhost:3000/chat/uploadfile
  @Output() pasamefileinfo = new EventEmitter();


  constructor( private http: HttpClient ) { }

  ngOnInit() {

    if (! this.data) {
      console.log('no existe data');
      this.data = {};
    }

    if (! this.defaultfolder) {
      console.log('no existe data');
      this.defaultfolder = '';
    }

  }

  onFileChange(event) {
    const that = this;

    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('defaultfolder', JSON.stringify(this.defaultfolder));
      formData.append('data', JSON.stringify(this.data));
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');

      this.http.post(String(this.fileurl), formData, {headers: headers} )
                .subscribe(
                      data => {
                            that.pasamefileinfo.emit(data);
                      },
                      err => console.error(err),
                      () => console.log('done loading')
            );
    }
  }

}
