import { trigger, state, style, transition, animate } from '@angular/animations';

export const flipState =  trigger('flipState', [
  state('active', style({
    transform: 'rotateY(179deg)'
  })),
  state('inactive', style({
    transform: 'rotateY(0)'
  })),
  transition('active => inactive', animate('500ms ease-out')),
  transition('inactive => active', animate('500ms ease-in'))
]);